import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import dateformat from 'dateformat';
import { Form, Text } from 'informed';
import slugify from 'slugify';
import styled from 'styled-components';
import withAuth from './withAuth';
import { Button } from './styles';

const Wrapper = styled.div`
    padding: 2rem;
`;

class EventAdd extends React.Component {
    constructor(props) {
        super(props);

        // Remember! This binding is necessary to make `this` work in the callback
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setFormApi = this.setFormApi.bind(this);
        this.titleChanged = this.titleChanged.bind(this);
        this.initialFolderValue = this.getFolderValue();
        this.state = {
            success: false,
            eventEditUrl: '',
        };
    }

    getFolderValue(folderStr = '') {
        const now = new Date();
        return `${dateformat(now, 'yyyy-mm-d')}_${slugify(folderStr, { lower: true })}`;
    }

    setFormApi(formApi) {
        this.formApi = formApi;
    }

    handleSubmit() {
        const {
            folder, title, description, added,
        } = this.formApi.getState().values;

        axios({
            method: 'post',
            url: `${ADAM_OLIVER_PHOTOGRAPHY_API_URL}/events`,
            data: {
                folder, title, description, added,
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        }).then((res) => {
            const { id } = res.data;
            this.setState({ success: true, eventEditUrl: `/events/${folder}/${id}/upload` });
        });
    }

    titleChanged(newValue) {
        this.formApi.setValue('folder', this.getFolderValue(newValue));
    }

    render() {
        const now = new Date();
        return this.state.success ?
            <Redirect to={this.state.eventEditUrl} /> :
            (
                <Wrapper>
                    <fieldset>
                        <legend>Add event</legend>
                        <Form getApi={this.setFormApi} onSubmit={this.handleSubmit}>
                            <label htmlFor="title">
                                Title
                                <Text
                                    type="text"
                                    field="title"
                                    placeholder="Event title"
                                    onValueChange={this.titleChanged}
                                />
                            </label>
                            <label htmlFor="added">
                                Date
                                <Text type="text" field="added" initialValue={dateformat(now, 'yyyy-mm-d')} />
                            </label>
                            <label htmlFor="description">
                                Description
                                <Text type="text" field="description" placeholder="Event description" />
                            </label>
                            <label htmlFor="folder">
                                Folder
                                <Text type="text" field="folder" initialValue={this.initialFolderValue} />
                            </label>
                            <Button type="submit">Save</Button>
                        </Form>
                    </fieldset>
                </Wrapper>
            );
    }
}

export default withAuth(EventAdd);
