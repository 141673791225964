import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import filePicker from './filePicker';

const BaseStyles = createGlobalStyle`
    ${normalize()}
    * {
        box-sizing: border-box;
    }
    body {
        color: #666;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        line-height: 1.5;
        background: #eee;
    }
    h1,
    h2,
    h3 {
       font-weight: 300;
    }
    a {
        text-decoration: none;
    }
    img {
        display: block;
    }
    fieldset {
        border: none;
        padding: 0;
    }
    legend {
        font-weight: bold;
        margin-bottom: 1rem;
        font-size: 1.2rem;
    }
    input[type='text'] {
        padding: 0.8em;
        font-size: 1.2em;
        display: block;
        width: 100%;
    }
    input[type='checkbox'] {
        margin: 0.8em;
    }
    label {
        display: block;
        margin-bottom: 1em;
    }
    ${filePicker}
`;

export default BaseStyles;
