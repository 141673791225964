import React from 'react';
import PropTypes from 'prop-types';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

const UserNav = ({ auth: { isAuthenticated } }) => {
    if (isAuthenticated()) {
        return <LogoutButton />;
    }
    return <LoginButton />;
};

UserNav.propTypes = {
    auth: PropTypes.shape({
        isAuthenticated: PropTypes.func.isRequired,
    }).isRequired,
};

export default UserNav;
