import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Auth from './Auth/Auth';
import { Button } from './styles';

const LogoutButton = ({ history }) => {
    const onClick = () => {
        const auth = new Auth();
        auth.logout(history.push);
    };

    return <Button onClick={onClick}>Logout</Button>;
};

LogoutButton.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(LogoutButton);
