import React from 'react';
import Auth from './Auth/Auth';
import LoginButton from './LoginButton';

const auth = new Auth();

const withAuth = Component => (props) => {
    if (auth.isAuthenticated()) {
        return <Component {...props} />;
    }
    return (
        <div style={{ textAlign: 'center' }}>
            <h1>You are not authenticated</h1>
            <LoginButton />
        </div>
    );
};

export default withAuth;
