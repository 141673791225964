import React from 'react';
import PropTypes from 'prop-types';
import { Cloudinary } from '@cloudinary/url-gen';
import { Transformation } from '@cloudinary/transformation-builder-sdk';
import { image } from '@cloudinary/url-gen/qualifiers/source';
import { Position } from '@cloudinary/url-gen/qualifiers/position';
import { source } from '@cloudinary/url-gen/actions/overlay';
import { compass } from '@cloudinary/url-gen/qualifiers/gravity';
import { Resize, scale } from '@cloudinary/url-gen/actions/resize';
import { sharpen } from '@cloudinary/url-gen/actions/adjust';

const imageLocation = 'https://adamoliverphotography.s3.amazonaws.com/';

const Image = ({
    alt, filename, folder, imageSize, isPortrait, publicId, sharpen: sharpenAmount,
}) => {
    const getFileName = (originalFilename, size) => {
        const re = /(^.*)\.(.*)$/;
        return originalFilename.replace(re, (match, file, extension) => `${file}.${size}.${extension}`);
    };

    let url;

    if (publicId) {
        const cld = new Cloudinary({
            cloud: {
                cloudName: 'adamoliverphotos',
            },
        });
        const overlayWidth = isPortrait
            ? imageSize * 0.5
            : Math.round(imageSize / 1.3);
        const overlayYPosition = imageSize > 800 ? 500 : 60;
        const myImage = cld
            .image(publicId)
            .adjust(sharpen().strength(sharpenAmount))
            .format('auto')
            .resize(Resize.limitFit().width(imageSize).height(imageSize))
            // eslint-disable-next-line max-len
            .overlay(source(image('watermark-large').transformation(new Transformation().resize(scale(overlayWidth)))).position(new Position()
                .gravity(compass('south_east'))
                .offsetY(overlayYPosition)))
            .addFlag('keep_iptc');
        url = myImage.toURL();
    } else {
        url = `${imageLocation}${folder}/${getFileName(filename, imageSize)}`;
    }

    return (
        <img
            src={url}
            alt={alt}
            style={{ maxWidth: '100%' }}
        />
    );
};

Image.defaultProps = {
    publicId: '',
};

Image.propTypes = {
    alt: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    folder: PropTypes.string.isRequired,
    imageSize: PropTypes.number.isRequired,
    isPortrait: PropTypes.bool.isRequired,
    publicId: PropTypes.string,
    sharpen: PropTypes.number.isRequired,
};

export default Image;
