import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import withAuth from './withAuth';
import { Table } from './styles';

const Events = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        axios.get(`${ADAM_OLIVER_PHOTOGRAPHY_API_URL}/events?limit=500`).then((res) => {
            setEvents(res.data.results);
        });
    }, []);

    return (
        <Table>
            <tbody>
                {events.map(event => (
                    <tr key={event.id}>
                        <td>
                            <Link to={`/events/${event.folder}/${event.id}/edit`}>{event.title}</Link>
                        </td>
                        <td>
                            <a href={`https://adamoliverphotography.com/events/${event.folder}/${event.id}`}>
                                View event
                            </a>
                        </td>
                        <td>
                            <Link to={`/events/${event.folder}/${event.id}/upload`}>Upload</Link>
                        </td>
                        <td>
                            <Link to={`/events/${event.folder}/${event.id}/edit`}>Edit</Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default withAuth(Events);
