import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Callback from './Callback';
import EventAdd from './EventAdd';
import EventEdit from './EventEdit';
import Events from './Events';
import Home from './Home';
import NotFound from './NotFound';
import Upload from './Upload';

const handleAuthentication = (auth, nextState) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
        auth.handleAuthentication(nextState.history.push);
    }
};

const Routes = ({ auth }) => (
    <Switch>
        <Route exact path="/" render={() => <Home auth={auth} />} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/add" component={EventAdd} />
        <Route exact path="/events/:folder/:id/upload" component={Upload} />
        <Route exact path="/events/:folder/:id/edit" component={EventEdit} />
        <Route
            path="/callback"
            render={(props) => {
                handleAuthentication(auth, props);
                return <Callback {...props} />;
            }}
        />
        <Route path="*" component={NotFound} />;
    </Switch>
);

Routes.propTypes = {
    auth: PropTypes.shape({}).isRequired,
};

export default Routes;
