import React from 'react';
import DropzoneComponent from 'react-dropzone-component';
import PropTypes from 'prop-types';
import 'dropzone/dist/min/dropzone.min.css';
import withAuth from './withAuth';
import { Button } from './styles';

class Upload extends React.Component {
    constructor(props) {
        super(props);

        const { id } = this.props.match.params;

        // For a full list of possible configurations,
        // please consult http://www.dropzonejs.com/#configuration
        this.djsConfig = {
            addRemoveLinks: true,
            acceptedFiles: 'image/jpeg,image/png,image/gif',
            autoProcessQueue: false,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
            parallelUploads: 10,
            params: {
                eventId: id,
            },
        };

        this.componentConfig = {
            iconFiletypes: ['.jpg'],
            showFiletypeIcon: true,
            postUrl: `${ADAM_OLIVER_PHOTOGRAPHY_API_URL}/photos`,
        };

        this.dropzone = null;

        this.handlePost = this.handlePost.bind(this);
    }

    handleFileAdded(file) {
        console.log(file);
    }

    handlePost() {
        this.dropzone.processQueue();
    }

    render() {
        const config = this.componentConfig;
        const { djsConfig } = this;

        // For a list of all possible events (there are many), see README.md!
        const eventHandlers = {
            init: (dz) => { this.dropzone = dz; },
            addedfile: this.handleFileAdded.bind(this),
        };

        return (
            <div>
                <DropzoneComponent config={config} eventHandlers={eventHandlers} djsConfig={djsConfig} />
                <Button onClick={this.handlePost}>Upload</Button>
            </div>
        );
    }
}

Upload.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            folder: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default withAuth(Upload);
