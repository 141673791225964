import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { clearFix } from 'polished';
import LogoImage from './svg/logo.svg';
import UserNav from './UserNav';

const Container = styled.div`
    background: #333;
    color: #fff;
    padding: 1rem;
    ${clearFix()}
`;

const StyledLogoImage = styled(LogoImage)`
    float: left;
`;

const Nav = styled.ul`
    float: right;
`;

const NavItem = styled.li`
    display: inline-block;
    margin-left: 1rem;
`;

const StyledLink = styled(Link)`
    color: #fff;
`;

const Header = ({ auth }) => (
    <Container>
        <StyledLogoImage width={75} height={75} />
        <Nav>
            <NavItem>
                <StyledLink to="/">Home</StyledLink>
            </NavItem>
            <NavItem>
                <StyledLink to="/events">Events</StyledLink>
            </NavItem>
            <NavItem>
                <UserNav auth={auth} />
            </NavItem>
        </Nav>
    </Container>
);

Header.propTypes = {
    auth: PropTypes.shape({}).isRequired,
};

export default Header;
