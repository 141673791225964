import { darken } from 'polished';
import styled from 'styled-components';

const Button = styled.button`
    font-size: 1em;
    margin: 1em 0;
    padding: 0.5em 1.5em;
    background: #50A0D8;
    border-radius: 3px;
    color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    display: inline-block;
    transition: background 0.3s;
    border: none;

    &:hover {
        cursor: pointer;
        background: ${darken(0.2, '#50A0D8')};
    }
`;

const Table = styled.table`
    background: #fff;
    border-collapse: collapse;
    width: 100%;

    tr {
        background: #eee;
        &:nth-child(odd) {
            background: #fff;
        }
    }

    td {
        padding: 0.5rem 1rem;
    }
`;

export { Button, Table };
