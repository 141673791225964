import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UserNav from './UserNav';

const Container = styled.div`
    text-align: center;
    margin: 5rem auto;
`;

const Home = ({ auth }) => (
    <Container>
        <h1>Adam Oliver Photography Admin</h1>
        <UserNav auth={auth} />
        <p>
            <a href="http://www.adamoliverphotography.com">Go to www.adamoliverphotography.com</a>
        </p>
    </Container>
);

Home.propTypes = {
    auth: PropTypes.shape({}).isRequired,
};

export default Home;
