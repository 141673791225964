import auth0 from 'auth0-js';

export default class Auth {
    constructor() {
        this.auth0 = new auth0.WebAuth({
            domain: 'adamoliverphotography.eu.auth0.com',
            clientID: 'wqLSeaNjaXNrpFXwi-l9ga9YHkLwkuZ1',
            redirectUri: 'https://admin.adamoliverphotography.com/callback',
            // redirectUri: 'http://localhost:8081/callback',
            audience: 'https://api.adamoliverphotography.com',
            responseType: 'token id_token',
            scope: 'openid',
        });

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
    }

    handleAuthentication(goToUrl) {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult, goToUrl);
            } else if (err) {
                goToUrl('/');
            }
        });
    }

    setSession(authResult, goToUrl) {
        // Set the time that the Access Token will expire at
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        // navigate to the home route
        goToUrl('/');
    }

    logout(goToUrl) {
        // Clear Access Token and ID Token from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        // navigate to the home route
        goToUrl('/');
    }

    isAuthenticated() {
        // Check whether the current time is past the
        // Access Token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    login() {
        this.auth0.authorize();
    }
}
