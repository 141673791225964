import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Form, Text } from 'informed';
import styled from 'styled-components';
import Image from './Image';
import withAuth from './withAuth';
import { Button } from './styles';

const Wrapper = styled.div`
    padding: 2rem;
`;

const PhotosList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const PhotosListItem = styled.li`
    padding: 1rem 0;
`;

const PhotoWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
`;

const FeaturedButton = styled(Button)`
    position: relative;
    top: -3.5rem;
    left: 0.5rem;
`;

class EventEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: null,
            loading: true,
        };
        this.updateFeatured = this.updateFeatured.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        axios.get(`${ADAM_OLIVER_PHOTOGRAPHY_API_URL}/events/${this.props.match.params.id}`)
            .then((res) => {
                const event = res.data;

                const sortedPhotos = event.photos.sort((a, b) => {
                    const aDate = new Date(a.added);
                    const bDate = new Date(b.added);
                    if (aDate > bDate) {
                        return -1;
                    }
                    return aDate < bDate ? 1 : 0;
                });

                this.setState({ event: { ...event, photos: sortedPhotos }, loading: false });
            });
    }

    updateFeatured(photoId, featured) {
        axios({
            method: 'patch',
            url: `${ADAM_OLIVER_PHOTOGRAPHY_API_URL}/photos/${photoId}/featured`,
            data: { featured },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        }).then(() => {
            const { event } = this.state;
            const updateEvent = {
                ...event,
                photos: event.photos.map((photo) => {
                    let updateFeatured = photo.featured;
                    if (photo.id === photoId) {
                        updateFeatured = featured;
                    }
                    return {
                        ...photo,
                        featured: updateFeatured,
                    };
                }),
            };

            this.setState({ event: updateEvent });
        });
    }

    handleSubmit(data) {
        const { id } = this.props.match.params;
        axios({
            method: 'patch',
            url: `${ADAM_OLIVER_PHOTOGRAPHY_API_URL}/events/${id}`,
            data,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        }).then((response) => {
            this.setState({ event: response.data });
        });
    }

    render() {
        if (this.state.loading) return <p>Loading</p>;
        const { event } = this.state;

        return (
            <Wrapper>
                <fieldset>
                    <legend>Edit event</legend>
                    <Form onSubmit={this.handleSubmit}>
                        <label htmlFor="title">
                            Title
                            <Text type="text" initialValue={event.title} field="title" />
                        </label>
                        <label htmlFor="description">
                            Description
                            <Text type="text" initialValue={event.description} field="description" />
                        </label>
                        <label htmlFor="added">
                            Added
                            <Text type="text" initialValue={event.added} field="added" />
                        </label>
                        <label htmlFor="folder">
                            Folder
                            <Text type="text" initialValue={event.folder} field="folder" />
                        </label>
                        <Button onClick={this.handleSubmit}>Save</Button>
                    </Form>
                </fieldset>

                <Button as={Link} to={`/events/${event.folder}/${event.id}/upload`}>Upload images</Button>
                <PhotosList>
                    {
                        event.photos.map(photo => (
                            <PhotosListItem key={photo.id}>
                                <div>
                                    <PhotoWrapper>
                                        {photo.title}
                                        <Image
                                            alt={photo.title}
                                            filename={photo.filename}
                                            publicId={photo.cloudinaryPublicID}
                                            folder={event.folder}
                                            imageSize={600}
                                            sharpen={100}
                                            isPortrait={photo.width / photo.height < 1}
                                        />
                                    </PhotoWrapper>
                                    <FeaturedButton onClick={() => this.updateFeatured(photo.id, !photo.featured)}>
                                        {
                                            photo.featured ?
                                                <span>Unset as featured</span> :
                                                <span>Set as featured</span>
                                        }
                                    </FeaturedButton>
                                </div>
                            </PhotosListItem>
                        ))
                    }
                </PhotosList>
            </Wrapper>
        );
    }
}

EventEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default withAuth(EventEdit);
