import React from 'react';
import Auth from './Auth/Auth';
import { Button } from './styles';

const LoginButton = () => {
    const onClick = () => {
        const auth = new Auth();
        auth.login();
    };

    return <Button onClick={onClick}>Login</Button>;
};

export default LoginButton;
