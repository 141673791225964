import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth from './Auth/Auth';
import Header from './Header';
import Routes from './Routes';
import BaseStyles from './styles/baseStyles';

const auth = new Auth();

const App = () => (
    <div>
        <BaseStyles />
        <Router>
            <Header auth={auth} />
            <Routes auth={auth} />
        </Router>
    </div>
);

export default App;
